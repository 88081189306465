import { categoryConstant } from "../actions/category.action";

const initialState = {
    loading: false,
    categories: [],
    categoryFilterUrl: '',
    searchKeywordUrl: '',
    searchResult: [],
    error: null,
};

export function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case categoryConstant.GET_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        
      };
    case categoryConstant.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case categoryConstant.GET_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
      
    case categoryConstant.SET_CATEGORY_URL_VALUE:
      return {
        ...state,
        loading: false,
        categoryFilterUrl: action.payload,
      };
    
    case categoryConstant.GET_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case categoryConstant.GET_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searchResult: action.payload,
      };
    case categoryConstant.GET_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case categoryConstant.SET_SEARCH_KEYWORD_VALUE:
      return {
        ...state,
        loading: false,
        searchKeywordUrl: action.payload,
      };
      
    default:
      return state;
  }
}

import React,{useState, useEffect} from 'react'
import './style.css'
import $ from 'jquery'
import { Link, useLocation } from 'react-router-dom'

const SideMenu = (props) => {
    const {pathname} = useLocation()
    const [activeCategory, setActiveCategory] = useState(null);
    const [subPath, setSubPath] = useState('/');
    const [clickedTexts, setClickedTexts] = useState([]);

    const handleActiveCategory = (slug, subPath) => {
        setSubPath(subPath);
        setActiveCategory(slug);
    };

    useEffect(() => {
        if (pathname !== subPath) {
            const sidebar = document.getElementById("sidebar");
            if (sidebar) {
                // Remove 'is-active' class only within the sidebar
                const ulElements = sidebar.querySelectorAll('ul');
                ulElements.forEach(ul => ul.classList.remove('is-active'));
    
                // Remove 'active' class only within the sidebar
                const activeElements = sidebar.querySelectorAll('.active');
                activeElements.forEach(el => el.classList.remove('active'));
            }
        }
        
        // Displaying selected category in the place of Back button here...
        $('.Main-category li a').on('click', function (e) {
            const text = $(e.target).text();
            
            if (e.target.classList.contains('screenOne')) {
                setClickedTexts(() => []);
            }

            if (!$(e.target).is(':last-child') && !clickedTexts.includes(text)) {
                setClickedTexts(prevTexts => {
                    const updatedTexts = [...prevTexts];
                    updatedTexts.push(text);
                    $('.js-drilldown-back a').text(updatedTexts[updatedTexts.length - 1]);
                    return updatedTexts;
                });
            }
        });
          
          $('.js-drilldown-back a').on('click', function () {
            setClickedTexts(prevTexts => {
              // Remove the last item from clickedTexts when going back
              const updatedTexts = [...prevTexts];
              updatedTexts.pop();
              
              if (updatedTexts.length > 0) {
                $('.js-drilldown-back a').text(updatedTexts[0]);
              } else {
                $('.js-drilldown-back a').text('');
              }
          
              return updatedTexts;
            });
          });          
        
      
    }, [pathname, subPath]);

    return (
        <div id="sidebar" class="sidebar" style={{fontFamily:'sans-serif'}}>
            <button class="close-btn" id="close-sidebar">&times;</button>
            <div id="menu" class="menu-container">
                <div id="menu" >
                    <ul id="example-menu" class="menu drilldown" data-drilldown>
                        <li className='Main-category'> 
                            {props.allCategories?.data?.length > 0 && props.allCategories.data.map((allcateg, index) => {
                                return (
                                <li key={index}>
                                    <a href='javascript:void(0);' className='screenOne'>
                                        {allcateg.name}
                                    </a>
                                    <hr />
                                    <ul className='vertical menu'>
                                        {allcateg.subCateg?.length > 0 && allcateg.subCateg.map((subCateg, index) => {
                                            if (subCateg.childSubCateg.length > 0) {
                                                return (
                                                    <li>
                                                        <a href='javascript:void(0);' className='screenTwo'>{subCateg.name}</a>
                                                        <hr className='subCateg-divider' />
                                                        <ul>
                                                            {subCateg.childSubCateg.length > 0 && subCateg.childSubCateg.map((childSubCateg, index) => {
                                                                const slugPath = `/browse/${allcateg.slug}/${subCateg.slug}/${childSubCateg.slug}`;
                                                                const subPath = `/browse/${childSubCateg.slug}`
                                                                return (
                                                                    <>
                                                                        <li key={index} id="childSubCateg" className={activeCategory === slugPath ? 'active' : ''}><Link id="close-sidebar" to={`/browse/${childSubCateg.slug}`} onClick={(e) => {
                                                                            props.handleCategoryFilter(e, slugPath);
                                                                            handleActiveCategory(slugPath, subPath);
                                                                            const sidebar = document.getElementById("sidebar");
                                                                            sidebar.classList.remove("active");
                                                                        }} className='screenThree'>{childSubCateg.name}</Link></li>
                                                                        <hr className='subCateg-divider' />
                                                                    </>

                                                                )
                                                            })}
                                                        </ul>
                                                    </li>
                                                )
                                            } else {
                                                return (
                                                    <li key={index}>
                                                        <Link to={`/browse/${allcateg.slug}/secondary/${subCateg.slug}`} onClick={(e) => handleCategoryFilter(e, '/browse/' + allcateg.slug + '/' + subCateg.slug)}>{subCateg.name}</Link>
                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </li>
                            )
                        })}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SideMenu
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Register from '../Register/Register';
import LoginForm from './LoginForm';
import './style.css'

function Login() {
    const [showLogin, setShowLogin] = useState(true);
    const [showRegister, setShowRegister] = useState(false);

    const showLoginForm = () => {
        setShowLogin(true);
        setShowRegister(false);
    };
    const showRegisterForm = () => {
        setShowRegister(true);
        setShowLogin(false);
        document.getElementById('first_name')?.focus();
    };

    return (
        <div>
            {/* <!-- Mobile login register  --> */}
            <section className="d-lg-none d-block" id="login_register">
                <div className="container">
                    <ul className="nav nav-tabs d-lg-none d-flex justify-content-center" role="tablist">
                        <li className="nav-item">
                            <a className={`nav-link ${showLogin === true ? 'active' : ''}`} data-toggle="tab" role="tab" onClick={showLoginForm} style={{ border: "none" }}>Login</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${showRegister === true ? 'active' : ''}`} data-toggle="tab" role="tab" onClick={showRegisterForm} style={{ border: "none" }} >Register</a>
                        </li>
                    </ul>

                    <div className="tab-content">
                        {/* <!-- SIGNUP TAB  --> */}
                        <div className={`register tab-pane p-0 ${showRegister === true ? 'active' : ''}`} id="register" role="tabpanel">
                            <h3>Create an account</h3>
                            <Register pId="mobile-reg-password" cnfId="mobile-reg-cnf-password" isAutoFocus={showRegister} />
                            <p className='text-center text-muted back-link login-alreay-have-account-text'>Already have an account?<Link to='#'> <span onClick={showLoginForm}>Login Here</span></Link></p>

                        </div>

                        {/* <!-- LOIGN TAB  --> */}
                        <div className={`login tab-pane p-0 ${showLogin === true ? 'active' : ''}`} id="login" role="tabpanel">
                            <LoginForm id="mobile-login-password" isAutoFocus={showLogin} />
                            <p className='text-center text-muted back-link login-alreay-have-account-text'>Don't have an account?<Link to=''> <span onClick={showRegisterForm} className=''>Create an Account</span></Link></p>
                        </div>

                    </div>
                </div>
            </section>

            {/* <!-- Start of Desktop Login & Registration Part --> */}
            <section id="login_register" className="d-lg-block d-none">
                <div className="container">
                    <div className="row">

                        {/* <!-- LOGIN --> */}
                        <div className="col-md-6 col-xs-12">
                            <div className="login tab-content" id="#login">
                                <div className="tab-pane active" id="login" role="tabpanel">
                                    <LoginForm id="web-login-password" />
                                </div>
                            </div>
                        </div>

                        {/* <!-- REGISTRATION --> */}

                        <div className="col-md-6 col-xs-12">
                            <div className="register tab-content" id="register">
                                <div className="tab-pane active" id="register" role="tabpanel">
                                    <h3>Create an account</h3>
                                    <Register pId="web-reg-password" cnfId="web-reg-cnf-password" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
}

export default Login;
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { profileSchema } from '../../../validationSchema/profileSchema';
import { FormInput } from '../../../../src/utility/FormInput';
import { auth, authConstant } from '../../../actions/auth.action';
import Message from '../../../components/Message/Message';
import './style.css';
import Sidebar from '../../../utility/Sidebar';

const schema = profileSchema;

function Profile(props) {
    const authState = useSelector((state) => state.auth);
    const [showPassword, setShowPassword] = useState(true);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    useEffect(() => {
        document.title = 'Shopthumb | Profile'
        if (!authState.userDetails.id) {
            return navigate("/");
        }
    }, [authState])

    const logOut = () => {
        dispatch(auth.signOut({ Token: authState.userDetails.token }))
    }

    const onSubmit = (data) => {
        dispatch({ type: authConstant.UPDATE_REQUEST });
        dispatch(auth.updateProfile(data, { Token: authState.userDetails.token }));
    }

    const togglePassword = () => {
        setShowPassword(showPassword === true ? false : true)
        const loginPasswordEl = document.getElementById('password');
        const newType = loginPasswordEl.type === 'password' ? 'text' : 'password'

        return loginPasswordEl.type = newType;
    }

    return (
        <div>
            <div class="container mb-5">
                <div class="row">
                    {/* Mobile View */}
                    <Sidebar 
                    authState={authState}
                    logOut={logOut}
                    component='profile'/>
                  

                    {/* Desktop View  */}
                    {/* <div class="col-md-2 d-none d-md-block">
           
                        <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                            <a class="nav-link mb-1 p-3 active" id="v-pills-acc-tab" data-toggle="pill" href="#v-pills-acc" role="tab" aria-controls="v-pills-acc" aria-selected="true">
                                <span class="text-uppercase">my account info</span></a>

                            <a class="nav-link mb-1 p-3" id="v-pills-cashback-tab" data-toggle="pill" href="#v-pills-cashback" role="tab" aria-controls="v-pills-cashback" aria-selected="false">

                                <span class="text-uppercase">cashback</span></a>

                            <a class="nav-link mb-1 p-3" id="v-pills-looks-tab" data-toggle="pill" href="#v-pills-looks" role="tab" aria-controls="v-pills-looks" aria-selected="false">

                                <span class="text-uppercase">looks</span>
                            </a>
                            <a class="nav-link mb-1 p-3" id="v-pills-closet-tab" data-toggle="pill" href="#v-pills-closet" role="tab" aria-controls="v-pills-closet" aria-selected="false">

                                <span class="text-uppercase">closet</span>
                            </a>
                            <a class="nav-link mb-1 p-3" id="v-pills-order-history-tab" data-toggle="pill" href="#v-pills-order-history" role="tab" aria-controls="v-pills-order-history" aria-selected="false">

                                <span class="text-uppercase">order history</span>
                            </a>
                            <a class="nav-link mb-1 p-3" id="v-pills-email-tab" data-toggle="pill" href="#v-pills-email" role="tab" aria-controls="v-pills-email" aria-selected="false">

                                <span class="text-uppercase">email preferences</span>
                            </a>
                            <Link class="nav-link mb-1 p-3" id="v-pills-calendar-tab" to='/my-favourite' style={{ pointerEvents: authState.logoutFlag === true ? 'block' : 'none' }}><span class="text-uppercase">My Favorite</span></Link>
                            <Link class="nav-link mb-1 p-3" id="v-pills-mydesigners-tab" to='/brand' style={{ marginLeft: "7px" }}>My Brands</Link>
                            
                            <a class="nav-link mb-1 p-3" id="v-pills-mystores-tab" data-toggle="pill" href="#v-pills-mystores" role="tab" aria-controls="v-pills-mystores" aria-selected="false">

                                <span class="text-uppercase">my stores</span>
                            </a>
                            <a class="nav-link mb-1 p-3" id="v-pills-suitcase-tab" data-toggle="pill" href="#v-pills-suitcase" role="tab" aria-controls="v-pills-suitcase" aria-selected="false">

                                <span class="text-uppercase">suitcase</span>
                            </a>
                            <a class="nav-link mb-1 p-3" id="v-pills-calendar-tab" data-toggle="pill" href="#v-pills-calendar" role="tab" aria-controls="v-pills-calendar" aria-selected="false">
                                <span class="text-uppercase">calendar</span>
                            </a>
                           
                            <Link class="nav-link mb-1 p-3" id="v-pills-calendar-tab" onClick={() => logOut()} to='/' style={{ pointerEvents: authState.logoutFlag === true ? 'block' : 'none' }}><span class="text-uppercase">Log out</span></Link>

                        </div>
                    </div> */}

                    <div class="col-md-10">
                        {/* <!-- Tabs content --> */}
                        <div class="tab-content" id="v-pills-tabContent">

                            <div class="tab-pane fade bg-white show active p-4" id="v-pills-acc" role="tabpanel" aria-labelledby="v-pills-acc-tab">
                                <h5 class="mb-5 text-center text-uppercase ui">Your information</h5>
                                {(authState.error.type === 'profile') ? <Message /> : ''}
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row mb-3 mx-0">
                                        <div className="form-group position-relative col-lg-6 mb-3 px-0">
                                            <FormInput
                                                register={register('first_name')}
                                                label="First Name"
                                                error={errors?.first_name}
                                                name="first_name"
                                                type="text"
                                                autoComplete="off"
                                                identity="first_name"
                                                defaultValue={authState.userDetails.first_name}

                                            />
                                        </div>

                                        <div className="form-group position-relative col-lg-6">
                                            <FormInput
                                                register={register('last_name')}
                                                label="Last Name"
                                                error={errors?.last_name}
                                                name="last_name"
                                                type="text"
                                                identity="last_name"
                                                autoComplete="off"
                                                defaultValue={authState.userDetails.last_name}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group position-relative" id="show_hide_password">
                                        <FormInput
                                            register={register('password')}
                                            label="Password"
                                            error={errors?.password}
                                            name="password"
                                            type="password"
                                            identity="password"
                                            autoComplete="off"
                                            id="password"
                                            defaultValue={authState.userDetails.password}
                                        />
                                        <div className="input-group-addon">
                                            <a href onClick={() => togglePassword()}><i className={`fa fa-eye${showPassword === true ? '-slash' : ''}`} aria-hidden="true"></i></a>
                                        </div>
                                    </div>

                                    {authState.submitted === true && (
                                        <button className="btn btn-dark signin" disabled>
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>{" "}
                                            Loading...
                                        </button>
                                    )}
                                    {authState.submitted === false && (
                                        <button type="submit" id="update" className="btn btn-dark signin mb-2">Update</button>
                                    )}

                                </form>


                            </div>

                            <div class="tab-pane fade shadow rounded bg-white p-4" id="v-pills-cashback" role="tabpanel" aria-labelledby="v-pills-cashback-tab">
                                <h4 class=" mb-4">Cashback</h4>
                                <p class=" text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>

                            <div class="tab-pane fade shadow rounded bg-white p-4" id="v-pills-looks" role="tabpanel" aria-labelledby="v-pills-looks-tab">
                                <h4 class=" mb-4">Looks</h4>
                                <p class=" text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>

                            <div class="tab-pane fade shadow rounded bg-white p-4" id="v-pills-closet" role="tabpanel" aria-labelledby="v-pills-closet-tab">
                                <h4 class=" mb-4">Closet</h4>
                                <p class=" text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
import { toast } from "react-toastify";
import { authService } from "../adapters/auth.services";
import history from "../helpers/history";

export const authConstant = {
  SIGNIN_REQUEST: "SIGNIN_REQUEST",
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_FAILURE: "SIGNIN_FAILURE",

  GOOGLE_SIGNIN_REQUEST: "GOOGLE_SIGNIN_REQUEST",
  GOOGLE_SIGNIN_SUCCESS: "GOOGLE_SIGNIN_SUCCESS",
  GOOGLE_SIGNIN_FAILURE: "GOOGLE_SIGNIN_FAILURE",

  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",

  SIGNOUT_REQUEST: "SIGNOUT_REQUEST",
  SIGNOUT_SUCCESS: "SIGNOUT_SUCCESS",
  SIGNOUT_FAILURE: "SIGNOUT_FAILURE",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_REQUEST: "UPDATE_REQUEST",
  UPDATE_FAILURE: "UPDATE_FAILURE",

  UPDATE_REGISTER_FLAG: "UPDATE_REGISTER_FLAG",
  UPDATE_FORGOT_PASS_FLAG: "UPDATE_FORGOT_PASS_FLAG",

  CLEAR_LOGIN_SIGNUP_ERROR: "CLEAR_LOGIN_SIGNUP_ERROR",
  CLEAR_LOGIN_SIGNUP_SUCCESS: "CLEAR_LOGIN_SIGNUP_SUCCESS",

  EMAIL_SUBSCRIPTION_REQUEST: "EMAIL_SUBSCRIPTION_REQUEST",
  EMAIL_SUBSCRIPTION_SUCCESS: "EMAIL_SUBSCRIPTION_SUCCESS",
  EMAIL_SUBSCRIPTION_FAILURE: "EMAIL_SUBSCRIPTION_FAILURE",
};

export const auth = {
  signUp,
  signIn,
  signOut,
  forgotPassword,
  resetPassword,
  updateProfile,
  updateRegisterFlag,
  updateForgotPasswordFlag,
  emailSubscription,
  googleSignIn,
};

async function updateForgotPasswordFlag(email) {
  return { type: authConstant.UPDATE_FORGOT_PASS_FLAG, payload: email };
}

async function updateRegisterFlag(email) {
  return { type: authConstant.UPDATE_REGISTER_FLAG, payload: email };
}


async function updateProfile(data, token) {
  const response = await authService.updateProfile("/api/customer/update", data, token);

  if (response.status === 200) {
    toast.success(response?.data?.message);

    return { type: authConstant.UPDATE_SUCCESS, payload: response.data };
  } else {
    return { type: authConstant.UPDATE_FAILURE, payload: response.data };
  }
}

async function googleSignIn(token) {
  const response = await authService.googleSignIn("/api/google-login", token);

  if (response?.status === 200) {
    // toast.success(response?.data?.message);
    return { type: authConstant.GOOGLE_SIGNIN_SUCCESS, payload: response.data?.user };
  } else {
    return { type: authConstant.GOOGLE_SIGNIN_FAILURE, payload: response?.data };
  }
}

async function signIn(data) {
  const response = await authService.signIn("/api/customer/signin", data);
  if (response.status === 200) {
    if (data.isRemember) {
      localStorage.setItem('data', JSON.stringify(data));
    } else {
      localStorage.setItem('data', null);
    }
    toast.success(response?.data?.message);
    return { type: authConstant.SIGNIN_SUCCESS, payload: response.data };
  } else {
    return { type: authConstant.SIGNIN_FAILURE, payload: response?.data };
  }
}

async function signOut(token) {
  const response = await authService.signOut("/api/customer/signout", {}, token);

  if (response.status === 200) {
    toast.success("Logged Out!");
    return { type: authConstant.SIGNOUT_SUCCESS, payload: response.data };
  } else {
    toast.error(response?.data?.message);
    return { type: authConstant.SIGNOUT_FAILURE, payload: response?.data };
  }
}

async function signUp(data) {
  const response = await authService.signUp("/api/customer/signup", data);
  if (response.status === 200) {
    if (response.status === 200) {
      if (data.isRemember) {
        localStorage.setItem('data', JSON.stringify(data))
      } else {
        localStorage.setItem('data', null)
      }
      toast.success(response?.data?.message);
    }
    return { type: authConstant.SIGNUP_SUCCESS, payload: response.data };
  } else {
    return { type: authConstant.SIGNUP_FAILURE, payload: response?.data };
  }
}

async function forgotPassword(data) {
  const response = await authService.signIn("/api/customer/forgotpassword", data);
  if (response.status === 200) {
    toast.success(response?.data?.message);
    return { type: authConstant.FORGOT_PASSWORD_SUCCESS, payload: response.data };
  } else {
    return { type: authConstant.FORGOT_PASSWORD_FAILURE, payload: response?.data };
  }
}

async function resetPassword(data) {
  const response = await authService.signIn("/api/customer/resetpassword", data);

  if (response.status === 200) {
    toast.success(response?.data?.message);
    return { type: authConstant.RESET_PASSWORD_SUCCESS, payload: response.data };
  } else {
    return { type: authConstant.RESET_PASSWORD_FAILURE, payload: response?.data };
  }
}

async function emailSubscription(data) {
  const response = await authService.emailSubscription("/api/email-subscription/create", data);

  if (response?.status === 200) {
    toast.success(response?.data?.message);
    return { type: authConstant.EMAIL_SUBSCRIPTION_SUCCESS, payload: response.data };
  } else {
    return { type: authConstant.EMAIL_SUBSCRIPTION_FAILURE, payload: response?.data };
  }
}
import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = (props) => {
  return (
    <>
    {props?.component==='brands'? 
      <div className="col-md-2 d-none d-md-block">
              <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                <Link className="nav-link mb-1 p-3" id="v-pills-acc-tab" data-toggle="pill" to="/profile" role="tab" aria-controls="v-pills-acc" aria-selected="true">
                  <span className="text-uppercase">my account info</span></Link>

                <Link className="nav-link mb-1 p-3" id="v-pills-myfav-tab" data-toggle="pill" to="#v-pills-myfav" role="tab" aria-controls="v-pills-myfav" aria-selected="false">

                  <span className="text-uppercase">my favourites</span>
                </Link>
                <Link className="nav-link mb-1 p-3 active" id="v-pills-mydesigners-tab" to='/brand' ><span className="text-uppercase">My Brand</span></Link>
                <Link className="nav-link mb-1 p-3" id="v-pills-calendar-tab" onClick={() => props?.logOut()} to='/' style={{ pointerEvents: props?.authState.logoutFlag === true ? 'block' : 'none' }}><span className="text-uppercase">Log out</span></Link>

              </div>
      </div>
      :
      <div class="col-md-2 d-none d-md-block">
      {/* <!-- Tabs nav --> */}
      <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">

          <Link class="nav-link mb-1 p-3 active" id="v-pills-acc-tab" data-toggle="pill" to="/profile" role="tab" aria-controls="v-pills-acc" aria-selected="true">
              <span class="text-uppercase">my account info</span></Link>

          {/* <Link class="nav-link mb-1 p-3" id="v-pills-order-history-tab" data-toggle="pill" to="#v-pills-order-history" role="tab" aria-controls="v-pills-order-history" aria-selected="false">

              <span class="text-uppercase">order history</span>
          </Link> */}
          <Link class="nav-link mb-1 p-3" id="v-pills-calendar-tab" to='/my-favourite' style={{ pointerEvents: props?.authState.logoutFlag === true ? 'block' : 'none' }}><span class="text-uppercase">My Favorite</span></Link>

        
          <Link class="nav-link mb-1 p-3" id="v-pills-mydesigners-tab" to='/brand' style={{ marginLeft: "7px" }}>My Brands</Link>
          <Link class="nav-link mb-1 p-3" id="v-pills-calendar-tab" onClick={() => props?.logOut()} to='/' style={{ pointerEvents: props?.authState.logoutFlag === true ? 'block' : 'none' }}><span class="text-uppercase">Log out</span></Link>

      </div>
      </div>
    }
</>
  )
}

export default Sidebar

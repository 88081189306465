import React, { useEffect, useState } from 'react';
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { profileSchema } from '../../../validationSchema/profileSchema';
// import { FormInput } from '../../../../src/utility/FormInput';
import { auth, authConstant } from '../../../actions/auth.action';
// import Message  from '../../../components/Message/Message';
import '../Brand/brand.css'
import Sidebar from '../../../utility/Sidebar';

// const schema = profileSchema;


function Brand(props) {
  const authState = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(auth.signOut({Token: authState.userDetails.token}))
  }

  return (
    <div>
      <div className="container mb-5">
        <div className="row">
         <Sidebar 
          authState={authState}
          logOut={logOut}
          component='brands'/>

          <div className="col-md-10">
            {/* <!-- Brand Page --> */}
            <div className="tab-content" id="v-pills-tabContent">
              <div className="row">
                <div className="col-md-2  ml-90">
                  <h5 className="h5-heading">MY DESIGNER </h5>
                  <b className="bold-font">G</b>
                  <p className="paragraph bold">GRLFRND</p>
                  <p className="paragraph line-ht">Gucci</p>
                </div>
                <div className="col-md-2 pt-27">
                  <p></p>
                  <b className="bold-font">P</b>
                  <p className="paragraph">Priscilla & Parker</p>
                  <div className="pt-40">
                    <b className="bold-font">S</b>
                    <p className="paragraph">Sophle Billev Brahe</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <h5 className="h5-heading">MANAGE DESIGNERS</h5>
                  <div>
                    <input type="search" id="gsearch" name="gsearch" className="seach-button" />
                    <svg fill="#c9c9c9" class="search-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"><path d="M 20.5 6 C 12.509634 6 6 12.50964 6 20.5 C 6 28.49036 12.509634 35 20.5 35 C 23.956359 35 27.133709 33.779044 29.628906 31.75 L 39.439453 41.560547 A 1.50015 1.50015 0 1 0 41.560547 39.439453 L 31.75 29.628906 C 33.779044 27.133709 35 23.956357 35 20.5 C 35 12.50964 28.490366 6 20.5 6 z M 20.5 9 C 26.869047 9 32 14.130957 32 20.5 C 32 23.602612 30.776198 26.405717 28.791016 28.470703 A 1.50015 1.50015 0 0 0 28.470703 28.791016 C 26.405717 30.776199 23.602614 32 20.5 32 C 14.130953 32 9 26.869043 9 20.5 C 9 14.130957 14.130953 9 20.5 9 z"></path></svg>
                  </div>

                  <table className="table-head">
                    <tr>
                      <td className="table-data">#</td>
                      <td className="table-data">A</td>
                      <td className="table-data">B</td>
                      <td className="table-data">C</td>
                      <td className="table-data">D</td>
                      <td className="table-data">E</td>
                      <td className="table-data">F</td>
                      <td className="table-data">G</td>
                      <td className="table-data">H</td>
                      <td className="table-data">I</td>
                    </tr>
                    <tr>
                      <td className="table-data">J</td>
                      <td className="table-data">K</td>
                      <td className="table-data">L</td>
                      <td className="table-data">M</td>
                      <td className="table-data">N</td>
                      <td className="table-data">O</td>
                      <td className="table-data">P</td>
                      <td className="table-data">Q</td>
                      <td className="table-data">R</td>
                      <td className="table-data">S</td>
                    </tr>
                    <tr>
                      <td className="table-data">T</td>
                      <td className="table-data">U</td>
                      <td className="table-data">V</td>
                      <td className="table-data">W</td>
                      <td className="table-data">X</td>
                      <td className="table-data">Y</td>
                      <td className="table-data">Z</td>
                      <td className="table-data">All</td>
                    </tr>
                  </table>
                  <div className="brand-table">
                    <div>
                      <b className="brand-table-font">#</b>
                    </div>
                    <div className='row ml-3px'>
                      <form className="col-md-6">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                          <label class="form-check-label" for="inlineCheckbox1"> 3.1 Philip Lim</label><br></br>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                          <label class="form-check-label" for="inlineCheckbox1">3*1</label><br></br>
                        </div>
                      </form>
                      <form className="col-md-6">
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                          <label class="form-check-label" for="inlineCheckbox1"> 3.1 Philip Lim</label><br></br>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                          <label class="form-check-label" for="inlineCheckbox1">3*1</label><br></br>
                        </div>
                      </form>
                    </div>
                    <div className='mt-30'>
                      <b className="brand-table-font">A</b>
                    </div>
                    <div className='row ml-3px'>
                      <form className="col-md-6">
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                          <label class="form-check-label" for="inlineCheckbox1">Adidas</label><br></br>
                      </div>
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                          <label class="form-check-label" for="inlineCheckbox1">Adidas*Stella</label><br></br>
                      </div>
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                          <label class="form-check-label" for="inlineCheckbox1">McCartney</label><br></br>
                      </div>
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                          <label class="form-check-label" for="inlineCheckbox1">A.L.C</label><br></br>
                      </div>
                      </form>
                      <form className="col-md-6">
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                          <label class="form-check-label" for="inlineCheckbox1">Aesop</label><br></br>
                      </div>
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                          <label class="form-check-label" for="inlineCheckbox1">AG Jeans</label><br></br>
                      </div>
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                          <label class="form-check-label" for="inlineCheckbox1">Alexander</label><br></br>
                      </div>
                      <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                          <label class="form-check-label" for="inlineCheckbox1">Altuzarra</label><br></br>
                      </div>
                      </form>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brand;